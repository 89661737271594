@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mern {
  background-image: url('./assets/mern.webp');
  background-size: cover;
  
}
.mongo {
  background-image: url('./assets/mongo.webp');
  background-size: contain;
  background-repeat: no-repeat;
  
}

.react {
  background-image: url('./assets/react.webp');
  background-size: cover;
  
}
.apache {
  background-image: url('./assets/apache.webp');
  background-size: cover;
  
}
.ps {
  background-image: url('./assets/ps.webp');
  background-size: cover;
  
}
.wp {
  background-image: url('./assets/wp.webp');
  background-size: cover;
  
}
.gql   {
  background-image: url('./assets/gql.webp');
  background-size: cover;
  
}

.mc   {
  background-image: url('./assets/mc.webp');
  background-size: cover;
  
}




li {
  font-size: 14px;
  margin-left: 10px;
  list-style-type: circle;
}